// extracted by mini-css-extract-plugin
export var bgGrey100 = "Branches-module--bg-grey-100--204a0";
export var bgGrey150 = "Branches-module--bg-grey-150--f38c4";
export var bgGrey200 = "Branches-module--bg-grey-200--c1ea2";
export var bgGrey300 = "Branches-module--bg-grey-300--65d33";
export var bgGrey400 = "Branches-module--bg-grey-400--76baa";
export var bgGrey500 = "Branches-module--bg-grey-500--3fde1";
export var bgGrey600 = "Branches-module--bg-grey-600--9c670";
export var bgGrey700 = "Branches-module--bg-grey-700--7bfa9";
export var bgGrey800 = "Branches-module--bg-grey-800--cf419";
export var bgGrey900 = "Branches-module--bg-grey-900--eea2e";
export var box = "Branches-module--box--c15a3";
export var hours = "Branches-module--hours--03e12";
export var imgCol = "Branches-module--imgCol--d46e9";
export var imgText = "Branches-module--imgText--e4831";
export var infoDiv = "Branches-module--infoDiv--61a8f";
export var mainImg = "Branches-module--mainImg--128aa";
export var mapImg = "Branches-module--mapImg--214eb";
export var row = "Branches-module--row--e6dd9";
export var text = "Branches-module--text--3a95c";
export var textDiv = "Branches-module--textDiv--c9dc5";
export var textGrey100 = "Branches-module--text-grey-100--e878f";
export var textGrey150 = "Branches-module--text-grey-150--4f44b";
export var textGrey200 = "Branches-module--text-grey-200--2a793";
export var textGrey300 = "Branches-module--text-grey-300--e7331";
export var textGrey400 = "Branches-module--text-grey-400--32c95";
export var textGrey500 = "Branches-module--text-grey-500--812f4";
export var textGrey600 = "Branches-module--text-grey-600--738ba";
export var textGrey700 = "Branches-module--text-grey-700--f1767";
export var textGrey800 = "Branches-module--text-grey-800--cf8cd";
export var textGrey900 = "Branches-module--text-grey-900--3573e";