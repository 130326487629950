import { graphql } from "gatsby"
import React, { useState } from "react"
import { Row, Col, Button } from "react-bootstrap"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import * as style from "./Branches.module.scss"
import { createLocalLink } from "../../utils"
import Accordion from "../UI/Accordion"
import Card from "../UI/Card"
import StoreLocatorModal from "../../templates/ProductUI/StoreLocatorModal"
import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"

export const fragment = graphql`
  fragment BranchesFragment on WpPage_Flexlayouts_FlexibleLayouts_Branches {
    sectionHeader
    paragraph
    serviceLink {
      url
      title
    }
    image {
      id
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 995
            placeholder: NONE
          )
        }
      }
    }
    branches {
      mapImage {
        id
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 890
              placeholder: NONE
            )
          }
        }
      }
      branchName
      street
      addressLine2
      city
      postcode
      phone
      openingHours {
        dayOfTheWeek
        hours
      }
    }
  }
`
const Branches = ({
  sectionHeader,
  paragraph,
  serviceLink,
  image,
  branches,
}) => {
  const [activeEventKey, setActiveEventKey] = useState(0)

  return (
    <>
      {/* MOBILE */}
      <section className="p-0 mb-2 mb-lg-5 d-lg-none">
        <h1 className="sr-only">Store Locator</h1>
        <Row className={`flex-column flex-md-row `}>
          <Col className={`px-md-0 ${style.imgCol}`}>
            <div className={`position-relative`}>
              {image && (
                <GatsbyImage
                  className="w-100"
                  image={getImage(image.localFile)}
                  alt={image.altText}
                />
              )}
              <div className={style.textDiv}>
                <h2 className={`text-white text-uppercase pt-4 pb-2`}>
                  {sectionHeader}
                </h2>
                <p className={`text-white font-weight-normal`}>{paragraph}</p>
                <a
                  href={createLocalLink(serviceLink.url)}
                  className="text-white">
                  <p className="pb-4">{serviceLink.title}</p>
                </a>
              </div>
            </div>

            <Accordion
              activeEventKey={activeEventKey}
              onToggle={setActiveEventKey}>
              {branches.map((branch, index) => {
                return (
                  <Card key={index}>
                    <address>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={index + 1}>
                          <div className="d-flex justify-content-between align-items-center">
                            {activeEventKey === index + 1 && (
                              <h3 className="text-uppercase mt-3 mb-4">
                                {branch.branchName}
                              </h3>
                            )}
                            {activeEventKey !== index + 1 && (
                              <h3 className="mt-2 text-uppercase">
                                {branch.branchName}
                              </h3>
                            )}
                            {activeEventKey !== index + 1 && (
                              <StaticImage
                                src="../../images/arrow-dropdown.png"
                                alt="Down arrow"
                                width={16}
                                height={16}
                                placeholder="none"
                              />
                            )}
                            {activeEventKey === index + 1 && (
                              <StaticImage
                                src="../../images/arrow-dropdown-up.png"
                                alt="Up arrow"
                                width={16}
                                height={16}
                                placeholder="none"
                              />
                            )}
                          </div>
                        </Accordion.Toggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey={index + 1}>
                        <Card.Body>
                          <div className={`mb-4 `} key={index}>
                            <p className="mb-0">
                              {branch.street} <br />
                              {branch.addressLine2 && (
                                <>
                                  {branch.addressLine2}
                                  <br />
                                </>
                              )}
                              {`${branch.city}, ${branch.postcode}`}
                              <br />
                              <span className="pt-3">{branch.phone}</span>
                            </p>
                            <a className={`${style.hours} `} href={"#"}>
                              {/* <p className="mt-3 mb-4" dangerouslySetInnerHTML={{ __html: 'Opening Hours' }}/> */}
                              <ToggleModal
                                toggle={show => {
                                  return (
                                    <p className="mt-2" onClick={show}>
                                      Opening Hours
                                    </p>
                                  )
                                }}
                                content={hide => {
                                  return (
                                    <ModalPortal
                                      hide={hide}
                                      size="xl"
                                      centered={true}>
                                      <StoreLocatorModal branch={branch} />
                                    </ModalPortal>
                                  )
                                }}
                              />
                            </a>
                            {branch.mapImage && (
                              <GatsbyImage
                                className="w-100"
                                image={getImage(branch.mapImage.localFile)}
                                alt={image.altText}
                              />
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </address>
                  </Card>
                )
              })}
            </Accordion>
          </Col>
        </Row>
      </section>

      {/* DESKTOP */}
      <section className="pt-3 my-3 mx-3  d-none d-lg-block">
        <Row className={style.row}>
          <Col lg={6}>
            <div className={`position-relative`}>
              {image && (
                <GatsbyImage
                  className={`${style.mainImg} w-100`}
                  image={getImage(image.localFile)}
                  alt={image.altText}
                />
              )}
              <div className={`position-absolute ${style.imgText}`}>
                <h2
                  className={`text-white text-uppercase pt-4 mt-3 pb-2`}>
                  {sectionHeader}
                </h2>
                <p
                  className={`text-white font-weight-normal lead ${style.text}`}>
                  {paragraph}
                </p>
                <a
                  href={createLocalLink(serviceLink.url)}
                  className="text-white">
                  <h3 className="pb-4 pt-3">{serviceLink.title}</h3>
                </a>
              </div>
            </div>
          </Col>

          <Col lg={6} className={`d-flex`}>
            {branches.map((branch, i) => {
              return (
                <div className="pl-3 pt-xl-4 pt-xxl-4" key={i}>
                  <div className={`pl-4 ${style.box}`}>
                    <address>
                      <h3 className="text-uppercase mt-5 mt-xxl-0 pt-xxl-5">
                        {branch.brancheName}
                      </h3>
                      <p className="mb-0">
                        {branch.street} <br />
                        {branch.addressLine2 && (
                          <>
                            {branch.addressLine2}
                            <br />
                          </>
                        )}
                        {`${branch.city}, ${branch.postcode}`}
                        <br />
                        <span className="d-block pt-2">{branch.phone}</span>
                      </p>
                    </address>
                    <a className={`${style.hours} `} href={"#"}>
                      <ToggleModal
                        toggle={show => {
                          return (
                            <p className="mt-2" onClick={show}>
                              Opening Hours
                            </p>
                          )
                        }}
                        content={hide => {
                          return (
                            <ModalPortal hide={hide} size="xl" centered={true}>
                              <StoreLocatorModal branch={branch} />
                            </ModalPortal>
                          )
                        }}
                      />
                    </a>
                  </div>
                  {branch.mapImage && (
                    <GatsbyImage
                      className={`${style.mapImg} w-100`}
                      image={getImage(branch.mapImage.localFile)}
                      alt={image.altText}
                    />
                  )}
                </div>
              )
            })}
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Branches
